body {
  margin: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  height: 100%; /* 100% Full-height */
  width: 300px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */

  display: flex;
  flex-direction: column;
  justify-content: center;
}

nav a:first {
  margin-top: 0 !important;
}

nav a {
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: background-color 0.25s linear;
}

nav a.active {
  color:#61dafb;
}

nav a:not(.fade-btn:first) {
  background-color: transparent;
  
}
nav a:not(:first-child):hover {
  background-color: rgba(90, 90, 90, 0.5);
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 48;
  margin-right: 10px;
}

.core {
  width: -webkit-calc(100% - 300px);
  width:    -moz-calc(100% - 300px);
  width:         calc(100% - 300px);
  height: 100%;

  margin-left: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.alc {
  align-items: center;
}

.proj {
  width: 80%;
  margin-left: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

html, body, #root { 
  height: 100%;
  background-color: #ffffff;
  /* background-color: #111; */
}

.first-div {
  width: 50%;
  padding-left: 50px;
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  text-align: left;
}
.second-div {
  width: 50%;
}

.App {
  flex-direction: row;
}

.profile-name {
  color: #61dafb;
}

.social-holder {
  margin-top: 30px ;
}
.social-link {
  margin-right: 20px;
}
.fa {
  font-size: 30px;
  color: black;
  width: 50px;
  text-align: center;
  text-decoration: none;
}


@keyframes wipe-enter {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
}



  @media (prefers-reduced-motion: no-preference) {
    .square-animation {
      animation: wipe-enter 1s 1;
    }
  }